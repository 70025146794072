<template>
  <div id="WaterElectricityConsume">
    <div class="filter-panel">
      <CSSelect i-width="36rpx" style="width: 230px; margin-right: 0">
        <el-date-picker
            type="datetime"
            placeholder="请选择开始时间"
            v-model="conditions.startTime"
            style="width: 214px"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            prefix-icon="el-icon-time"
            :editable="false"
        >
        </el-date-picker>
      </CSSelect>
      <span class="inline-gray"> </span>
      <CSSelect i-width="36rpx" style="width: 230px">
        <el-date-picker
            type="datetime"
            placeholder="请选择结束时间"
            v-model="conditions.endTime"
            style="width: 214px"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            prefix-icon="el-icon-time"
            :editable="false"
        >
        </el-date-picker>
      </CSSelect>
      <div
          style="
                    vertical-align: middle;
                    margin-bottom: 15px;
                    display: inline-block;
                "
      >
        <input
            type="text"
            placeholder="电表编号/关联房间/当前业主/租客"
            v-model="conditions.selectPut"
            style="
                        color: #000;
                        width: 240px;
                        height: 30px;
                        padding-left: 10px;
                        border-radius: 4px;
                        border: 1px solid #979797;
                        margin-right: 20px;
                    "
        />
      </div>
      <button @click="query()" type="button" class="btn btn-primary btn-sm">
        查询
      </button>
    </div>
    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:header>
          <div
              class="table-header-panel"
              style="
              display: flex;
              align-items: center;justify-content: space-between;
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        line-height: 20px;
                        color: #000000;
                        opacity: 1;
                    "
          >
              <div>
                  累计使用度数:<span style="margin-right: 50px">{{ accumulatedTon }}度</span>
                  累计消耗金额:<span>{{ accumulatedAccount }}元</span>
              </div>
              <div>
                  <button type="button" class="btn btn-primary btn-sm" @click="zipBtn()" :disabled="loading">
                      导出数据
                  </button>
              </div>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>电表编号</th>
            <th>当前读数</th>
            <th>当前电价（元/度）</th>
            <th>关联房间</th>
            <th>当前业主/租客</th>
            <th>累计使用度数</th>
            <th>累计消耗金额</th>
            <th>历史业主/租客</th>
            <th>备注</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="item in electricityList" :key="item.meterId">
            <td>
              {{ item.imei }}
            </td>
            <td>
              {{ item.ton }}
            </td>
            <td>
              {{ setPrice(item.basisPrice,item.additionPrice) || '-'}}
            </td>
            <td>
              {{ item.correlationRoom || "-" }}
            </td>
            <td>
                            <span
                                class="btn-active"
                                @click="getInformation(item)"
                                v-if="item.companyName"
                            >
                                {{ item.companyName }}
                            </span>
              <span v-else>
                                {{ "-" }}
                            </span>
            </td>
            <td>
              {{ item.cumulativeNum ? item.cumulativeNum + "度" : "-" }}
            </td>
            <td>
                            <span
                                class="allow-click"
                                @click="getCumulativeMony(item)"
                                v-if="item.cumulativeAmount"
                            >
                                {{ item.cumulativeAmount }}元
                            </span>
              <span v-else>-</span>
            </td>
            <td>
              <span class="allow-click" @click="getHistory(item)">查看</span>
            </td>
            <td v-if="item.commit">
                            <span class="allow-click" @click="lookComment(item.commit)">
                                查看
                            </span>
            </td>
            <td v-else>
              -
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination/>
    </div>
  </div>
</template>

<script>
import CSTable from "@/components/common/CSTable";
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination.vue";
import dayjs from "dayjs";
import {BigNumber} from "@/utils/bignumber.js";
import {
    exportWaterTonDianUrl,
    hydropowerContractInformationUrl,
    selectWaterTonUrl
} from "@/requestUrl";

export default {
  name: 'WaterElectricityConsume',
  components: {
    CSTable,
    Pagination,
    CSSelect,
  },
  mounted() {
    this.query();
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.query(pageNo);
    });
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });
  },
  updated() {
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
  },
  data() {
    return {
        loading:false,
      filterHeight: '',
      conditions: {
        startTime: dayjs().subtract(30, "day").format("YYYY-MM-DD") + " 00:00",
        endTime: dayjs().format("YYYY-MM-DD") + " 23:59",
        selectPut: "",
      },
      accumulatedAccount: '',
      accumulatedTon: '',
      electricityList: [],
    }
  },
  methods: {
      //导出数据
      zipBtn(){
          this.loading = true;
          this.$fly
              .post(exportWaterTonDianUrl, {
                  search: this.conditions.selectPut, //搜索框内容
                  pageSize: 1, //页面大小`
                  pageNo: 1, //页码
                  startDate: this.conditions.startTime, //开始时间     这两个参数查询消耗记录传
                  endDate: this.conditions.endTime, //结束时间
                  regionCode: this.$vc.getCurrentRegion().code, //区域编码
                  consume: true
              }, {
                  responseType: "arraybuffer",
                  headers: {
                      notParse: true,
                  },
              })
              .then((res) => {
                  const blob = new Blob([res], { type: "application/vnd.ms-excel" });
                  console.log("temp:" + res)
                  if ("download" in document.createElement("a")) {
                      const elink = document.createElement("a");
                      elink.style.display = "none";
                      elink.href = URL.createObjectURL(blob);
                      elink.download = `${this.conditions.startTime}至${this.conditions.endTime}电表消耗记录`;
                      document.body.appendChild(elink);
                      elink.click();
                      URL.revokeObjectURL(elink.href);
                      document.body.removeChild(elink);
                  } else {
                      navigator.msSaveBlob(blob, fileName);
                  }
              }).finally(res => {
              this.loading = false;
          });
      },
    setPrice(x,y){
      let a = new BigNumber(x);
      return a.plus(y);
    },
    //查询按钮
    query(page = 1, pageSize = 10) {
      this.$fly
          .post(selectWaterTonUrl, {
            search: this.conditions.selectPut, //搜索框内容
            pageSize: pageSize, //页面大小`
            pageNo: page, //页码
            startDate: this.conditions.startTime, //开始时间     这两个参数查询消耗记录传
            endDate: this.conditions.endTime, //结束时间
            regionCode: this.$vc.getCurrentRegion().code, //区域编码
            consume:true
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            if (page == 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total || res.data.datas.length,
                pageSize: pageSize,
                currentPage: page,
              });
            }
            this.accumulatedTon = res.data.accumulatedTon;
            this.accumulatedAccount = res.data.accumulatedAccount;
            this.electricityList = res.data.datas;
          });
    },
    getHistory(item) {
      this.$router.push({
        name: "history",
        params: {
          id: 2,
          meterId: item.meterId,
        },
      });
    },
    getCumulativeMony(item) {
      this.$router.push({
        name: "Cumulative",
        params: {
          id: 2,
          conditions: {
            startTime: this.conditions.startTime,
            endTime: this.conditions.endTime,
          },
          type: 2,
          meterId: item.meterId,
          rid: '',
        },
      });
    },
    // 当前租客
    async getInformation(item) {
      this.ContractInformationList = await this.$fly
          .post(hydropowerContractInformationUrl, {
            cid: item.cid,
            lesseeId: item.lesseeId,
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            return res.data;
          });
      this.$CSDialog.alert({
        width: "700px",
        title: "当前业主/租客",
        messageHtml: `
                    <div class="comment-panel" style="--item-label: 160px;">
                        <div class="item right">
                            <div class="item-label">企业/个人名称</div>
                            <div>${
            this.ContractInformationList.companyName
        }</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">合同时间</div>
                            <div>${
            this.ContractInformationList.startDate
                ? this.ContractInformationList.startDate
                : "-"
        }至${
            this.ContractInformationList.endDate
                ? this.ContractInformationList.endDate
                : "-"
        }</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">对接人</div>
                            <div>${
            this.ContractInformationList.deputyName
        }</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">对接人手机号</div>
                            <div>${
            this.ContractInformationList.deputyPhone
        }</div>
                        </div>
                    </div>
                `,
      });
    },
    lookComment: function lookComment(comment) {
      this.$CSDialog.alert({
        title: '查看备注',
        message: comment
      });
    },
  }
}
</script>

<style lang="stylus" scoped>

</style>
